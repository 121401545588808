<template>
  <div class="py-8">
    <v-app-bar flat color="transparent">
      <app-bar-nav-icon />
      <v-toolbar-title class="breadcrumbs d-flex align-center">
        <v-icon class="text-light-blue">{{ icons.arrowLeft }}</v-icon>
        <router-link class="text-decoration--none" :to="{ name: 'users' }">
          Users
        </router-link>
      </v-toolbar-title>
    </v-app-bar>

    <div class="ma-4" v-if="user">
      <div class="display-2 font-weight-bold my-6">
        {{ displayName }}
      </div>

      <v-row dense>
        <v-col cols="12">
          <v-tabs v-model="currentTab">
            <v-tab v-for="item in tabItems" :key="item">
              {{ item }}
            </v-tab>
          </v-tabs>
        </v-col>

        <v-col cols="12">
          <v-tabs-items v-model="currentTab">
            <v-tab-item>
              <v-row>
                <v-col cols="12" md="6">
                  <v-card flat>
                    <v-card-text>
                      <v-alert
                        dense
                        outlined
                        type="warning"
                        color="orange darken-3"
                        max-width="400px"
                        v-if="!!user.blocked_at"
                      >
                        This account is <strong>disabled</strong>.
                      </v-alert>

                      <avatar-form
                        :user="user"
                        @change="avatarChange"
                        @avatarRemove="userAvatarRemoved"
                        :avatarLoading="avatarLoading"
                        disabled-upload
                      />

                      <v-form ref="form" class="user-form">
                        <v-divider></v-divider>

                        <h3 class="py-5 primary--text">User Information</h3>

                        <label class="text-field-label required">
                          First Name
                        </label>
                        <v-text-field
                          flat
                          solo
                          label="First Name"
                          required
                          class="mt-2"
                          v-model="form.first_name"
                          :error-messages="form.$getError('first_name')"
                          :loading="loading"
                        ></v-text-field>

                        <label class="text-field-label required">
                          Last Name
                        </label>
                        <v-text-field
                          flat
                          solo
                          label="Last Name"
                          required
                          class="mt-2"
                          v-model="form.last_name"
                          :error-messages="form.$getError('last_name')"
                          :loading="loading"
                        ></v-text-field>

                        <label class="text-field-label required">Email</label>
                        <v-text-field
                          v-model="form.email"
                          flat
                          solo
                          label="Email"
                          required
                          class="mt-2"
                          :error-messages="form.$getError('email')"
                          :loading="loading"
                        >
                          <v-tooltip slot="append" top v-if="user.email">
                            <template v-slot:activator="{ on }">
                              <v-icon
                                color="success"
                                v-on="on"
                                v-if="user.email_verified"
                                >{{ icons.check }}</v-icon
                              >
                              <v-icon color="warning" v-on="on" v-else>{{
                                icons.alert
                              }}</v-icon>
                            </template>
                            <span v-if="user.email_verified"
                              >Email Verified</span
                            >
                            <span v-else>Unverified email</span>
                          </v-tooltip>
                        </v-text-field>

                        <v-checkbox
                          :input-value="user && user.blocked_at"
                          label="Disable user account"
                          color="orange darken-3"
                          @mousedown="showModal = true"
                        />

                        <div class="mt-2">
                          <v-btn
                            color="error"
                            class="mr-4 px-6"
                            height="40px"
                            width="100%"
                            @click="deleteDialog = true"
                          >
                            Delete
                          </v-btn>
                        </div>

                        <div class="mt-4">
                          <v-btn
                            color="primary"
                            class="mr-4 px-6"
                            height="40px"
                            width="100%"
                            @click="updateUserDetails"
                            :loading="form.$busy"
                            >Update</v-btn
                          >
                        </div>
                      </v-form>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-tab-item>

            <v-tab-item>
              <v-row no-gutters dense>
                <v-col v-if="user.subscription" cols="12">
                  <v-row>
                    <v-col class="ma-4" cols="4">
                      <v-card color="basil" flat>
                        <v-card color="#385F73" dark>
                          <v-card-title class="text-h5"> MONTHLY </v-card-title>

                          <v-card-subtitle>
                            Ends at:
                            <b>
                              {{
                                user.subscription.ends_at
                                  | formatedDate('YYYY-MMM-DD')
                              }}
                            </b>
                          </v-card-subtitle>

                          <v-card-actions>
                            <v-chip
                              v-if="!user.subscription.is_expired"
                              class="ma-2"
                              close
                              color="teal"
                              text-color="white"
                              close-icon="mdi-delete"
                              small
                            >
                              Active
                            </v-chip>

                            <v-chip
                              v-else
                              class="ma-2"
                              close
                              color="error"
                              text-color="white"
                              close-icon="mdi-delete"
                              small
                            >
                              Expired
                            </v-chip>

                            <v-chip
                              v-if="user.subscription.is_bypassed"
                              class="ma-2"
                              color="orange"
                              text-color="white"
                              small
                            >
                              Bypassed
                            </v-chip>
                          </v-card-actions>
                        </v-card>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-col>

                <v-col v-if="canBypass" cols="12">
                  <div class="ma-4">
                    <v-btn color="success" @click="bypassDialogOpen = true">
                      Bypass subscription
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>
    </div>

    <v-dialog v-model="bypassDialogOpen" persistent max-width="390">
      <v-card>
        <v-card-title class="text-h5"> Bypass Subscrption </v-card-title>
        <v-card-text>
          <v-row class="mt-2">
            <v-col cols="12">
              <v-select
                v-model="subScriptionForm.provider"
                :items="providers"
                item-text="name"
                item-value="value"
                label="Platform"
                hide-details
                outlined
              />
            </v-col>

            <v-col cols="12">
              <v-menu
                v-model="datePickerMenu"
                :close-on-content-click="false"
                max-width="290"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-bind="attrs"
                    v-model="subScriptionForm.subscription_date"
                    v-on="on"
                    label="Subscription Date"
                    @click:clear="subScriptionForm.subscription_date = null"
                    hide-details
                    clearable
                    readonly
                    outlined
                  />
                </template>
                <v-date-picker
                  v-model="subScriptionForm.subscription_date"
                  @change="datePickerMenu = false"
                />
              </v-menu>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions class="pb-4">
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            :disabled="bypassLoading"
            @click="bypassDialogOpen = false"
            text
          >
            Cancel
          </v-btn>

          <v-btn
            :loading="bypassLoading"
            @click="continueBypass()"
            color="primary"
          >
            Continue
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-overlay absolute :value="loading" opacity="0.2">
      <v-progress-circular indeterminate color="primary" size="64" />
    </v-overlay>

    <v-snackbar v-model="snackbar.show" right :color="snackbar.color">
      {{ snackbar.message }}
      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" text @click="snackbar.show = false">OK</v-btn>
      </template>
    </v-snackbar>

    <ConfirmModal
      v-model="showModal"
      title="Disable Account"
      :message="disableMessage"
      @cancel="showModal = false"
      @confirm="blockAccount"
    />

    <v-dialog v-model="deleteDialog" max-width="290">
      <v-card>
        <v-card-title class="headline">Delete User Account</v-card-title>

        <v-card-text>
          Are you sure you want to delete this account ?
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="deleteDialog = false">Cancel</v-btn>
          <v-btn
            color="red"
            text
            @click="deleteUser"
            :loading="isLoadingDelete"
          >
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  mdiChevronLeft,
  mdiCheck,
  mdiAlertCircleOutline,
  mdiMinus,
  mdiCheckCircle,
  mdiStarCircle,
  mdiCloseCircle,
} from '@mdi/js'
import { mapState, mapActions, mapMutations } from 'vuex'
import AppBarNavIcon from '@/layouts/shared/AppBarNavIcon'
import AvatarForm from './components/AvatarForm'
import ConfirmModal from '@/components/modals/ConfirmModal'

import Form from '@/utils/form'

export default {
  name: 'UserDetails',

  components: {
    AvatarForm,
    AppBarNavIcon,
    ConfirmModal,
  },

  data() {
    return {
      datePickerMenu: false,
      bypassDialogOpen: false,
      currentTab: 0,
      icons: {
        arrowLeft: mdiChevronLeft,
        check: mdiCheck,
        cCheck: mdiCheckCircle,
        alert: mdiAlertCircleOutline,
        minus: mdiMinus,
        star: mdiStarCircle,
        remove: mdiCloseCircle,
      },
      loading: false,
      avatarLoading: false,
      deleteDialog: false,

      form: new Form({
        id: '',
        first_name: '',
        last_name: '',
        email: '',
        company: '',
        job_title: '',
        bio: '',
        phone_number: '',
        work_email: '',
        websites: [],
      }),

      showCropperModal: false,
      avatarTimestamp: new Date().getTime(),
      tabItems: ['Info', 'Subscription'],

      snackbar: {
        show: false,
        message: null,
        color: '',
      },

      showModal: false,
      isLoadingDelete: false,

      providers: [
        { value: 'app-store', name: 'iOS' },
        { value: 'google-play', name: 'Google Play' },
      ],

      subScriptionForm: {
        provider: 'app-store',
        subscription_date: null,
      },
    }
  },

  computed: {
    ...mapState({
      user: (state) => state.user.userDetails,
      authUser: (state) => state.auth.user,
      bypassLoading: (state) => state.user.bypassLoading,
    }),

    displayName() {
      return this.form.full_name || this.form.email
    },

    disableMessage() {
      const action = this.user && this.user.blocked_at ? 'unblock' : 'block'
      return `Are you sure you want to ${action} this account?`
    },

    canBypass() {
      if (!this.user.subscription) {
        return true
      }

      return this.user.subscription && this.user.subscription?.is_expired
    },
  },

  methods: {
    ...mapActions({
      getUserDetails: 'user/getUserDetails',
      changeAvatar: 'user/changeAvatar',
      updateUser: 'user/updateUser',
      blockUser: 'user/blockUser',
      bypassSubscription: 'user/bypassSubscription',
    }),

    ...mapMutations({
      clearUserDetails: 'user/clearUserDetails',
      setSelectedUserAvatar: 'user/setSelectedUserAvatar',
      userAvatarRemoved: 'user/userAvatarRemoved',
      setBypassLoading: 'user/setBypassLoading',
    }),

    async getUser() {
      this.loading = true

      await this.getUserDetails(this.$route.params.id)

      for (const [key, value] of Object.entries(this.user)) {
        if (key in this.form) {
          this.form[key] = value
        }
      }

      this.loading = false
    },

    async avatarChange(image) {
      this.avatarLoading = true
      try {
        let data = new FormData()
        data.append('user_id', this.user.id)
        data.append('avatar', image)
        await this.changeAvatar(data)
        this.showSnackbar('Avatar successfully updated!', 'success')
      } catch (err) {
        this.showSnackbar(err.response.data.message, 'error')
      } finally {
        this.avatarLoading = false
      }
    },

    async updateUserDetails() {
      this.form.$busy = true
      this.form.$clearErrors()
      this.updateUser(this.form.$data())
        .then(() => {
          this.form.$busy = false
          this.showSnackbar('User details successfully updated!', 'success')
        })
        .catch((err) => {
          this.form.$busy = false
          if (err.response.status === 422) {
            this.form.$setErrors(err.response.data.errors)
          }
        })
    },

    async continueBypass() {
      this.setBypassLoading(true)

      try {
        await this.bypassSubscription({
          id: this.user.id,
          ...this.subScriptionForm,
        })

        this.getUser()
        this.bypassDialogOpen = false
        this.setBypassLoading(false)
      } catch (e) {
        this.setBypassLoading(false)
      }
    },

    addWebsite() {
      this.form.websites.push('')
    },

    removeWebsite(i) {
      this.form.websites.splice(i, 1)
    },

    deleteUser() {
      this.isLoadingDelete = true
      this.user.delete().then(() => {
        this.isLoadingDelete = false
        this.deleteDialog = false
        this.showSnackbar('User deleted successfully!')
        this.$router.replace('/users')
      })
    },

    showSnackbar(message, color) {
      this.snackbar.message = message
      this.snackbar.show = true
      this.snackbar.color = color
    },
    blockAccount(value) {
      this.form.$busy = true
      const action = this.user.blocked_at ? 'unblocked' : 'blocked'
      const message = this.user.blocked_at ? 'enabled' : 'disabled'

      this.blockUser({
        id: this.user.id,
        action,
      })
        .then(() => {
          this.form.$busy = false
          this.showModal = false
          this.showSnackbar(`User successfully ${message}!`, 'success')
        })
        .catch((err) => {
          this.form.$busy = false
          if (err.response.status === 422) {
            this.form.$setErrors(err.response.data.errors)
          }
        })
    },
  },

  created() {
    this.getUser()
  },

  destroyed() {
    this.clearUserDetails()
  },

  watch: {
    $route() {
      this.getUser()
    },
  },
}
</script>

<style lang="scss" scoped>
.user-form {
  @media (max-width: 420px) {
    width: 100%;
  }
}
.required::after {
  content: ' *';
  color: red;
}
</style>
